import React from "react";

type ButtonProps = {
  label: string;
  disabled: boolean;
  onButtonClick: () => void;
};

function Button({ label, disabled, onButtonClick }: ButtonProps) {
  return (
    <button
      className="form-button"
      type="submit"
      disabled={disabled}
      onClick={() => {
        onButtonClick();
      }}
    >
      {label}
    </button>
  );
}

export default Button;
