import axios from "axios";

const isProduction = process.env.NODE_ENV === "production";
const rootUrl = isProduction
  ? "https://api.vanescola.com/"
  : "http://localhost:5080/";

const apiAxios = axios.create({
  baseURL: rootUrl,
  headers: {
    common: "",
  },
});

export default apiAxios;
