import axios from "axios";
import { useEffect, useState } from "react";
import { Form } from "../components/Form";
import Logo from "../components/Logo";
import { ParentAlert } from "../components/ParentAlert";
import apiAxios from "../infrastructure/apiClient";

type Role = "unknown" | "owner" | "parent";

export type OwnerRegistrationRequest = {
  role: Role;
  name: string;
  phoneNumber: string;
  email: string;
  vanNames: string[];
  businessName: string;
  reCaptchaToken: string;
  confirmEmail: string;
  aware: boolean;
  affiliateCode: string | null;
};

type SubmitResponse = { success: true } | { success: false; errorMsg: string };

export type PageState =
  | { state: "editing" | "sending" }
  | { state: "error"; errorMsg: string };

async function submitForm(
  data: OwnerRegistrationRequest
): Promise<SubmitResponse> {
  try {
    await apiAxios.post("registration", data);
    return { success: true };
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 400) {
      return { success: false, errorMsg: error.response.data };
    }
    return {
      success: false,
      errorMsg:
        "Ocorreu um erro. Por favor tente novamente mais tarde ou entre em contato com o suporte técnico.",
    };
  }
}

function RegistrationScreen(props: { onSuccess: () => void }) {
  const [ownerRegistration, setOwnerRegistration] =
    useState<OwnerRegistrationRequest>({
      role: "unknown",
      name: "",
      phoneNumber: "",
      email: "",
      vanNames: [""],
      businessName: "",
      reCaptchaToken: "",
      confirmEmail: "",
      aware: false,
      affiliateCode: null,
    });

  const [pageState, setPageState] = useState<PageState>({ state: "editing" });

  useEffect(function loadAffiliateCode() {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    if (code !== null) {
      localStorage.setItem("affiliateCode", code);
    }
  }, []);

  return (
    <div className="container">
      <Logo />
      <h1 className="form-title">Formulário de Cadastro</h1>
      <div className="form-radio-wrapper">
        <div className="form-radio">
          <input
            type="radio"
            checked={ownerRegistration.role === "owner"}
            onChange={() => {
              setOwnerRegistration({ ...ownerRegistration, role: "owner" });
            }}
            id="vanOwner"
          />
          <label className="form-check-label" htmlFor="vanOwner">
            Sou <strong>TRANSPORTADOR ESCOLAR</strong>
          </label>
        </div>
        <div className="form-radio">
          <input
            type="radio"
            checked={ownerRegistration.role === "parent"}
            onChange={() => {
              setOwnerRegistration({ ...ownerRegistration, role: "parent" });
            }}
            id="parent"
          />
          <label className="form-check-label" htmlFor="parent">
            Sou <strong>PAI / RESPONSÁVEL DE ALUNO</strong>
          </label>
        </div>
      </div>

      <ParentAlert hidden={ownerRegistration.role !== "parent"} />

      <Form
        hidden={ownerRegistration.role !== "owner"}
        ownerRegistration={ownerRegistration}
        pageState={pageState}
        onNameChange={(text) => {
          setOwnerRegistration({
            ...ownerRegistration,
            name: text,
          });
        }}
        onPhoneNumberChange={(text) => {
          const numbers = text.replaceAll(/[^\d]/g, "");
          setOwnerRegistration({
            ...ownerRegistration,
            phoneNumber: numbers.slice(0, 11),
          });
        }}
        onEmailChange={(text) =>
          setOwnerRegistration({
            ...ownerRegistration,
            email: text,
          })
        }
        onConfirmEmailChange={(text) =>
          setOwnerRegistration({
            ...ownerRegistration,
            confirmEmail: text,
          })
        }
        onVanNameChange={(text, i) => {
          const vanNames = ownerRegistration.vanNames.slice();
          vanNames[i] = text;
          setOwnerRegistration({
            ...ownerRegistration,
            vanNames: vanNames,
          });
        }}
        onVansSizeIncrease={() => {
          const vanNames = ownerRegistration.vanNames.slice();
          vanNames.push("");
          setOwnerRegistration({
            ...ownerRegistration,
            vanNames: vanNames,
          });
        }}
        onVansSizeDecrease={() => {
          const vanNames = ownerRegistration.vanNames.slice();
          vanNames.pop();
          setOwnerRegistration({
            ...ownerRegistration,
            vanNames: vanNames,
          });
        }}
        onBusinessNameChange={(text) =>
          setOwnerRegistration({
            ...ownerRegistration,
            businessName: text,
          })
        }
        onAwareChange={() => {
          setOwnerRegistration({
            ...ownerRegistration,
            aware: !ownerRegistration.aware,
          });
        }}
        onSubmit={async () => {
          setPageState({ state: "sending" });
          const submitResult = await submitForm({
            ...ownerRegistration,
            affiliateCode: localStorage.getItem("affiliateCode"),
            email: ownerRegistration.email.toLowerCase().trim(),
          });

          if (!submitResult.success) {
            setPageState({
              state: "error",
              errorMsg: submitResult.errorMsg,
            });
            return;
          }

          props.onSuccess();
        }}
        setupRecaptcha={(reCaptchaToken: string) => {
          setOwnerRegistration({
            ...ownerRegistration,
            reCaptchaToken,
          });
        }}
        resetRecaptcha={() => {
          setOwnerRegistration({
            ...ownerRegistration,
            reCaptchaToken: "",
          });
        }}
      />
    </div>
  );
}

export default RegistrationScreen;
