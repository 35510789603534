export function ParentAlert({ hidden }: { hidden: boolean }) {
  return (
    <div className={hidden ? "hidden" : "info-alert"}>
      <hr />
      <p>
        <span>Você não precisa preencher este formulário!</span>
      </p>
      <p>
        O seu transportador escolar utiliza o aplicativo VanEscola para você
        acompanhar a localização do veículo em tempo real, além de receber
        notificações quando ele estiver próximo e quando seu filho embarcar e
        desembarcar da van.
      </p>
      <p>
        E para você ter tudo isso é <span>MUITO SIMPLES</span>, basta seguir
        esse 3 passos:
      </p>
      <ol>
        <li>
          Peça para seu transportador escolar informar seu{" "}
          <span>código de acesso</span>
        </li>
        <li>
          Baixe o aplicativo para seu celular Android ou Apple em:{" "}
          <span>
            <a target="blank" href="https://www.vanescola.com/aplicativos">
              Aplicativos VanEscola
            </a>
          </span>{" "}
        </li>
        <li>
          Entre no aplicativo usando o <span>CÓDIGO</span> fornecido pelo
          motorista
        </li>
      </ol>
      <p>
        Pronto! Agora, quando o motorista iniciar a rota do seu filho, a van
        aparecerá no mapa do aplicativo.
      </p>
      <p>Quer entender melhor? Veja o vídeo explicativo:</p>
      <div className="video">
        <iframe src="https://www.youtube.com/embed/rvG7SguCTIY"></iframe>
      </div>
    </div>
  );
}
