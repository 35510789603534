import { useEffect, useState } from "react";

type ValidationState = "default" | "valid" | "invalid";

type InputProps = {
  label: string;
  text: string;
  onTextChange: (text: string) => void;
  isInputValid: boolean;
  isRequired?: boolean;
  isVan?: boolean;
};

function Input({
  label,
  text,
  onTextChange,
  isInputValid,
  isRequired = true,
  isVan = false,
}: InputProps) {
  const [validationState, setValidationState] =
    useState<ValidationState>("default");

  useEffect(() => {
    const isInitialized = validationState !== "default";
    if (text !== "" || isInitialized) {
      setValidationState(isInputValid ? "valid" : "invalid");
    }
  }, [text]);

  return (
    <div className="input-group float-label">
      <label
        className={
          text === "" ? "style-down form-label" : "style-up form-label"
        }
        htmlFor={text}
      >
        {label}
        <span
          style={{ color: "#f57a01" }}
          className={isRequired ? "" : "optional"}
        >
          {isRequired ? " *" : " (opcional)"}
        </span>
      </label>
      <input
        className={`form-input ${isVan ? "van" : ""} ${validationState}`}
        value={text}
        onChange={(e) => {
          onTextChange(e.target.value);
        }}
        required={isRequired}
      />
    </div>
  );
}

export default Input;
