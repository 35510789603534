import React, { useState } from "react";
import RegistrationScreen from "./screens/RegistrationScreen";
import SuccessScreen from "./screens/SuccessScreen";
import "./App.css";

function App() {
  const [appState, setAppState] = useState<"wizard" | "submitSuccess">(
    "wizard"
  );

  return appState === "wizard" ? (
    <RegistrationScreen onSuccess={() => setAppState("submitSuccess")} />
  ) : (
    <SuccessScreen />
  );
}

export default App;
