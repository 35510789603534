import Button from "../components/Button";
import Logo from "../components/Logo";

function SuccessScreen() {
  return (
    <div className="container info-alert">
      <Logo />
      <h1 className="success-title">Parabéns!</h1>
      <p>
        <span>Seu pré-cadastro foi efetuado com sucesso!</span>
      </p>
      <p>
        Em alguns minutos você receberá um <span>e-mail</span> com o assunto "
        <span>[VanEscola] Ative sua conta</span>". Leia o email com atenção,
        pois ele tem todas as instruções para entender o funcionamento do
        VanEscola.
      </p>
      <p>
        Assim que você tiver acesso ao Portal VanEscola, você poderá cadastrar
        os estudantes e seus responsáveis. Além disso, caso você seja
        proprietário de mais vans escolares, você poderá entrar em contato com
        nosso suporte para cadastrar outros motoristas e vans!
      </p>
      <p>
        Nós logo entraremos em contato com você, mas a qualquer momento você
        pode falar conosco pelo e-mail{" "}
        <span>
          <a target="blank" href="mailto:contato@vanescola.com">
            contato@vanescola.com
          </a>
        </span>{" "}
        ou pelo WhatsApp comercial{" "}
        <span>
          <a
            target="blank"
            href="https://api.whatsapp.com/send?phone=5551982819595&text=Ol%C3%A1%2C%20tenho%20d%C3%BAvidas%20sobre%20o%20Cadastro%20VanEscola."
          >
            (51)98281-9595
          </a>
        </span>{" "}
        que teremos o prazer de esclarecer sua dúvidas.
      </p>
      <p>Estamos à disposição!</p>
      <p>Atenciosamente,</p>
      <p>
        <span>Equipe VanEscola</span>
      </p>
      <Button
        label={"Voltar"}
        disabled={false}
        onButtonClick={() =>
          (window.location.href = "https://www.vanescola.com/")
        }
      />
    </div>
  );
}

export default SuccessScreen;
