import Button from "./Button";
import { isValidText } from "./Form";
import Input from "./Input";

export function VansInput({
  vanNames,
  onVanNameChange,
  onVansSizeIncrease,
  onVansSizeDecrease,
}: {
  vanNames: string[];
  onVanNameChange: (vanName: string, index: number) => void;
  onVansSizeIncrease: () => void;
  onVansSizeDecrease: () => void;
}) {
  return (
    <>
      {vanNames.map((vanName, i) => (
        <Input
          label="Nome da van"
          isInputValid={isValidText(vanName)}
          text={vanName.trimStart()}
          onTextChange={(vanName) => onVanNameChange(vanName, i)}
          isVan={true}
          key={i}
        />
      ))}
      <div className="button-group">
        <Button
          label="Adicionar mais vans"
          disabled={false}
          onButtonClick={onVansSizeIncrease}
        />
        <Button
          label="Remover última van"
          disabled={vanNames.length <= 1}
          onButtonClick={onVansSizeDecrease}
        />
      </div>
    </>
  );
}
